import "../styles/app.css";
import "../styles/collegepark.css";
import "../styles/text.css";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import { isLoggedIn } from "../utils/AuthService";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Toaster } from "react-hot-toast";
import AdminRoute from "./shared/AdminRoute";
import CalibrationPage from "./CalibrationPage";
import CreateUserPage from "./users/CreateUserPage";
import DebugPage from "./DebugPage";
import DeviceList from "./devices/DeviceList";
import DeviceView from "./devices/DeviceView";
import FirmwareList from "./firmware/FirmwareList";
import FirmwareView from "./firmware/FirmwareView";
import ForgotPassword from "./auth/ForgotPassword";
import Home from "./home/Home";
import Login from "./auth/Login";
import MyAccount from "./myaccount/MyAccount";
import NotFound from "./NotFound";
import PrivateRoute from "./shared/PrivateRoute";
import React, { useEffect, useState } from "react";
import ResetPassword from "./auth/ResetPassword";
import SiteContainerRoute from "./shared/SiteContainerRoute";
import TokenService from "../utils/TokenService";
import TrainingPage from "./training/TrainingPage";
import UploadFirmwareForm from "./forms/UploadFirmwareForm";
import UsersList from "./users/UsersList";
import UserView from "./users/UserView";

const App = () => {
  let navigate = useNavigate();
  let location = useLocation();

  const unauthenticatedRoutes = [
    "calibrate",
    "debug",
    "forgot-password",
    "login",
    "set-password",
    "training",
  ];
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [isAdmin, setIsAdmin] = useState(true);

  useEffect(() => {
    const authenticated = isLoggedIn();
    const admin = TokenService.isUserAdmin();

    setIsAuthenticated(authenticated);
    setIsAdmin(admin);

    if (!authenticated) {
      let unauthenticatedRoute = false;
      unauthenticatedRoutes.forEach((route) => {
        if (location.pathname.includes(route)) unauthenticatedRoute = true;
      });
      if (!unauthenticatedRoute) navigate("/login");
    }
  }, []);

  const login = () => {
    setIsAuthenticated(true);
    setIsAdmin(TokenService.isUserAdmin());
    navigate("/");
  };

  const logout = () => {
    setIsAuthenticated(false);
    setIsAdmin(false);
    TokenService.clearStorage();
    navigate("/login");
  };

  return (
    <>
      <Toaster position="top-right" />
      <Routes>
        <Route
          element={
            <SiteContainerRoute
              isAuthenticated={isAuthenticated}
              logout={logout}
            />
          }>
          <Route
            path="/login"
            element={<Login isAuthenticated={isAuthenticated} login={login} />}
          />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route element={<PrivateRoute isAuthenticated={isAuthenticated} />}>
            <Route element={<AdminRoute isAdmin={isAdmin} />}>
              <Route path="users">
                <Route path="create" element={<CreateUserPage />} />
                <Route path=":user_id" element={<UserView />} />
                <Route index element={<UsersList />} />
              </Route>
              <Route path="devices">
                <Route path=":deviceType/:id" element={<DeviceView />} />
                <Route path=":deviceType" element={<DeviceList />} />
                <Route index element={<Navigate to="/" />} />
              </Route>
              <Route path="firmware">
                <Route path=":deviceType/:id" element={<FirmwareView />} />
                <Route
                  path=":deviceType/upload"
                  exact
                  element={<UploadFirmwareForm />}
                />
                <Route path=":deviceType" element={<FirmwareList />} />
                <Route index element={<Navigate to="/" />} />
              </Route>
              <Route index element={<Home isAdmin={isAdmin} />} />
            </Route>
            <Route path="my-account" element={<MyAccount />} />
          </Route>
          <Route path="debug" element={<DebugPage />} />
          <Route path="calibrate" element={<CalibrationPage />} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path="training/:type" element={<TrainingPage />} />
      </Routes>
    </>
  );
};

export default App;
