import { Button, Col, Container, Row } from "reactstrap";
import { collegeParkFlatLogo } from "../../../assets/images";
import { Field, Form } from "react-final-form";
import FieldLabel from "../shared/FieldLabel";
import NoAuthApi from "../../api/NoAuthApi";
import React, { useState } from "react";
import toast from "react-hot-toast";

const ForgotPassword = () => {
  const [loading, setLoading] = useState();

  const submit = async (vals) => {
    setLoading(true);
    NoAuthApi.post("forgot-password", { ...vals })
      .then((res) => toast.success(res.data.message))
      .finally(() => setLoading());
  };

  return (
    <Container>
      <Row>
        <Col lg="5" md="7" className="mx-auto p-4 border bg-white cp-rounded">
          <Form
            onSubmit={submit}
            render={({ handleSubmit, pristine }) => (
              <form onSubmit={handleSubmit}>
                <div className="text-center mb-4">
                  <img
                    src={collegeParkFlatLogo}
                    alt="College Park Logo"
                    className="w-75 rounded"
                  />
                </div>
                <div>
                  <h3>Forgot Password</h3>
                </div>
                <div className="my-3">
                  <FieldLabel label="Email Address" />
                  <Field
                    required
                    name="email"
                    component="input"
                    className="cp-input"
                  />
                </div>
                <div className="text-right">
                  <Button
                    className="btn-cp"
                    type="submit"
                    disabled={pristine || loading}>
                    Submit{loading ? "ting" : ""}
                  </Button>
                </div>
              </form>
            )}></Form>
        </Col>
      </Row>
    </Container>
  );
};

export default ForgotPassword;
