import { Button, Container } from "reactstrap";
import React from "react";

const CalibrationPage = () => (
  <Container className="text-center">
    <a href="cpss://calibrate" target="_blank" rel="noreferrer">
      <Button className="btn-cp">Begin Calibration</Button>
    </a>
  </Container>
);

export default CalibrationPage;
