import { Field } from "react-final-form";
import FieldCol from "./FieldCol";
import FieldLabel from "../shared/FieldLabel";
import PropTypes from "prop-types";
import React from "react";

const FormField = ({
  className,
  component,
  label,
  name,
  options,
  render,
  required = false,
  type,
  validate,
}) => (
  <FieldCol>
    <FieldLabel label={label} required={required} />
    <Field
      className={className}
      component={component}
      name={name}
      options={options}
      render={render}
      required={required}
      type={type}
      validate={validate}
    />
  </FieldCol>
);

FormField.propTypes = {
  className: PropTypes.string,
  component: PropTypes.any,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.any,
  render: PropTypes.any,
  required: PropTypes.bool,
  type: PropTypes.string,
  validate: PropTypes.any,
};

export default FormField;
