import { ASC, DESC } from "../../utils/constants";
import PropTypes from "prop-types";
import React from "react";

const TableHead = ({ columns = [], handleSorting, sortField, sortOrder }) => {
  const handleSortingChange = (accessor) => {
    const newOrder = accessor === sortField && sortOrder === ASC ? DESC : ASC;
    handleSorting(accessor, newOrder);
  };

  return (
    <thead>
      <tr className="header hoverable unselectable">
        {columns.map(({ label, accessor }) => {
          const headerClass =
            sortField === accessor
              ? sortOrder === ASC
                ? "up"
                : "down"
              : "default";
          return (
            <th
              key={accessor}
              className={headerClass}
              onClick={() => handleSortingChange(accessor)}>
              {label}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

TableHead.propTypes = {
  handleSorting: PropTypes.func,
  data: PropTypes.array,
  columns: PropTypes.array,
  sortField: PropTypes.string,
  sortOrder: PropTypes.string,
};

export default TableHead;
