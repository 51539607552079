import { Col, Row } from "reactstrap";
import PropTypes from "prop-types";
import React from "react";

const ViewHeader = ({ children }) => (
  <Row>
    <Col className="p-2 px-0 rounded cp-header-text">
      <h1 className="m-0">{children}</h1>
    </Col>
  </Row>
);

ViewHeader.propTypes = {
  children: PropTypes.any,
};

export default ViewHeader;
