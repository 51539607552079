import "./table.css";
import { ASC } from "../../utils/constants";
import { Button } from "reactstrap";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import TableBody from "./TableBody";
import TableHead from "./TableHead";

const Table = ({ rowClick = () => {}, data = [], columns = [] }) => {
  const pageSize = 10;
  const [filter, setFilter] = useState();
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const [currentData, setCurrentData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPages, setMaxPages] = useState(1);

  /** Update the search filter */
  const updateFilter = (e) => {
    setFilter(e.target.value);
  };

  /** set order values */
  const updateSortValues = (field, order) => {
    setSortField(field);
    setSortOrder(order);
  };

  /** Go forward one page */
  const incrementPage = () => {
    if (currentPage >= maxPages) {
      setCurrentPage(maxPages);
      return;
    }

    setCurrentPage(currentPage + 1);
  };

  /** Go back one page */
  const decrementPage = () => {
    if (currentPage <= 1) {
      setCurrentPage(1);
      return;
    }
    setCurrentPage(currentPage - 1);
  };

  /** Initialize our dataset */
  useEffect(() => {
    setFilter();
    setSortField();
    setSortOrder();
    setFilteredData(data);
  }, [data]);

  /** Update the data to display when the filtered dataset changes or we change pages */
  useEffect(() => {
    let startIndex = (currentPage - 1) * pageSize;
    if (startIndex < 0) {
      startIndex = 0;
    }
    const endIndex = startIndex + pageSize;
    setCurrentData(filteredData.slice(startIndex, endIndex));
  }, [currentPage, filteredData]);

  /** Update max pages and current page if the filtered dataset changes */
  useEffect(() => {
    const newMaxPages = Math.max(Math.ceil(filteredData.length / pageSize), 1);
    setMaxPages(newMaxPages);
    if (currentPage >= newMaxPages) {
      setCurrentPage(newMaxPages);
    }
  }, [filteredData]);

  /** trigger filter and sort whenever the filter or ordering changes */
  useEffect(() => {
    sortAndFilterData();
  }, [filter, sortField, sortOrder]);

  /** get fields we can search on */
  const getSearchableFields = () =>
    [...columns].filter((x) => x.searchable).map((x) => x.accessor);

  /** Filter and sort data to display */
  const sortAndFilterData = () => {
    const searchableFields = getSearchableFields();
    let newFilteredData = [...data];

    // filter on searchable fields
    if (filter) {
      newFilteredData = newFilteredData.filter((item) =>
        searchableFields.some(
          (key) =>
            item[key] && // verify data exists
            item[key]
              .replace(" ", "")
              .toLowerCase()
              .includes(filter.replace(" ", "").toLowerCase())
        )
      );
    }

    // sort data
    if (sortField) {
      newFilteredData = newFilteredData.sort(
        (a, b) =>
          sortLogic(a[sortField], b[sortField]) * (sortOrder === ASC ? 1 : -1)
      );
    }
    setFilteredData(newFilteredData);
  };

  /** Logic for sorting values in the table */
  const sortLogic = (a, b) => {
    if (!a && !b) return 0;
    if (!a) return 1;
    if (!b) return -1;

    // check if we are a version
    if (shouldParseAsVersion(a, b)) {
      return a
        .replace(/\d+/g, (n) => +n + 100000)
        .localeCompare(b.replace(/\d+/g, (n) => +n + 100000));
    }

    // not a version, try a number comparison or just string comparison
    return shouldParseAsNumber(a, b)
      ? Number.parseFloat(a) - Number.parseFloat(b)
      : a.toString().localeCompare(b.toString());
  };

  /** Regex to parse string as a version */
  const versionRegex = /^\d+\.\d+\.\d+$/;

  /** Checks if strings are version #'s */
  const shouldParseAsVersion = (a, b) =>
    a.toString().match(versionRegex) && b.toString().match(versionRegex);

  /** Regex to parse string as a number */
  const numberRegex = /^\d+(.\d+)?$/;

  /**
   * Helper to determine if we can do a number comparison between our values
   * Should match decimal or integer format to be valid
   */
  const shouldParseAsNumber = (a, b) =>
    a.toString().match(numberRegex) && b.toString().match(numberRegex);

  /** Rendered component */
  return (
    <>
      <input
        className="mb-3 p-2 rounded border"
        value={filter}
        onChange={updateFilter}
        placeholder="Search"
      />
      <table style={{ tableLayout: "fixed" }}>
        <TableHead
          columns={columns}
          handleSorting={updateSortValues}
          sortField={sortField}
          sortOrder={sortOrder}
        />
        <TableBody
          columns={columns}
          tableData={currentData}
          rowClick={rowClick}
        />
      </table>
      <div className="w-100 text-center mt-4">
        <Button
          disabled={currentPage <= 1}
          className="btn-cp border mx-2"
          onClick={decrementPage}>
          Prev
        </Button>
        Page {currentPage} of {maxPages}
        <Button
          disabled={currentPage >= maxPages}
          className="btn-cp border mx-2"
          onClick={incrementPage}>
          Next
        </Button>
        <div>
          {filteredData.length === 0 ? "0" : (currentPage - 1) * pageSize + 1}-
          {(currentPage - 1) * pageSize + currentData.length} of{" "}
          {filteredData.length}
        </div>
      </div>
    </>
  );
};

Table.propTypes = {
  rowClick: PropTypes.func,
  data: PropTypes.array,
  columns: PropTypes.array,
};

export default Table;
