import { Row } from "reactstrap";
import PropTypes from "prop-types";
import React from "react";

const ItemGroup = ({ children }) => <Row>{children}</Row>;

ItemGroup.propTypes = {
  children: PropTypes.any,
};

export default ItemGroup;
