import decode from "jwt-decode";
import TokenService from "./TokenService";

export function isLoggedIn() {
  const token = getToken();
  return !!token && isTokenValid(token);
}

function getToken() {
  return TokenService.getAccessToken();
}

function isTokenValid(token) {
  const expiration = getExpirationDate(token);
  if (!expiration) {
    return false;
  }
  return expiration > Date.now();
}

function getExpirationDate(idToken) {
  try {
    const token = decode(idToken);
    if (!token?.exp) {
      return null;
    }

    return new Date(0).setUTCSeconds(token.exp);
  } catch (e) {
    return null;
  }
}
