import axios from "axios";
import toast from "react-hot-toast";

/**
 * Axios instance which does not set authorization headers
 *
 * Used for site functions that do not require being logged in
 * (logging in, resetting password, etc.)
 */
const NoAuthApi = axios.create({
  baseURL: "/api/v1",
});

/**
 * Send language header in request
 */
NoAuthApi.interceptors.request.use((config) => {
  config.headers.Language = window.navigator.language;
  return config;
});

/**
 * Handle errors
 */
NoAuthApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    toast.error(error.response?.data?.error || "An error occurred with your request");
    return Promise.reject(error);
  },
);

export default NoAuthApi;
