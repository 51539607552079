import PropTypes from "prop-types";
import React from "react";

const Split = ({ className = "w-100", children }) => (
  <div className={`${className} d-flex justify-content-between`}>
    {children}
  </div>
);

Split.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Split;
