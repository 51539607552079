import {
  Button,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { Field, Form } from "react-final-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FOOT, KNEE } from "../../utils/constants";
import { useNavigate, useParams } from "react-router-dom";
import { userFriendlyFileSize } from "../../utils/FileFormat";
import Api from "../../api/Api";
import ConfirmationModal from "../shared/ConfirmationModal";
import fileDownload from "js-file-download";
import ItemGroup from "../shared/ItemGroup";
import ItemProperty from "../shared/ItemProperty";
import React, { useEffect, useState } from "react";
import Split from "../shared/Split";
import toast from "react-hot-toast";
import ViewHeader from "../shared/ViewHeader";

const FirmwareView = () => {
  const { deviceType, id } = useParams();
  const [firmware, setFirmware] = useState();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const toggleDeleteModal = () => setDeleteOpen(!deleteOpen);
  const [changingTestStatus, setChangingTestStatus] = useState(false);
  const toggleTestStatus = () => setChangingTestStatus(!changingTestStatus);

  const navigate = useNavigate();

  const validDeviceTypes = [FOOT, KNEE];

  const getFirmware = () =>
    Api.get(`${deviceType}_firmware/${id}`).then((res) => {
      setChangingTestStatus(false);
      setFirmware(res.data.firmware);
    });

  const updateFirmware = () => {
    Api.patch("firmware/" + firmware?.id, {
      is_test_firmware: !firmware.is_test_firmware,
    }).then(() => getFirmware());
  };

  const deleteFirmware = () =>
    Api.delete(`firmware/${id}`).then(() => {
      toast.success("Successfully deleted firmware: " + firmware?.version);
      toggleDeleteModal();
      navigate("/firmware/" + deviceType);
    });

  const downloadFirmware = () =>
    Api({
      method: "GET",
      url: "/firmware/" + firmware?.id + "/download",
      responseType: "blob",
    }).then((response) => fileDownload(response.data, firmware?.file_name));

  useEffect(() => {
    if (!validDeviceTypes.includes(deviceType)) navigate("/");
    getFirmware();
  }, [deviceType]);

  return firmware ? (
    <Container>
      <ViewHeader>
      {firmware.version}
        {firmware?.is_test_firmware && (
          <span className="ps-3" title="This is marked as test firmware and is only visible to administrators">
            🛠️
          </span>
        )}
      </ViewHeader>
      <ItemGroup>
        <ItemProperty
          label="Minimum ICD Version"
          value={firmware?.minimum_icd_version}
        />
        <ItemProperty label="Filename" required>
          <Split>
            <h4>{firmware?.file_name}</h4>
            <h4
              className="hoverable"
              title={`Download "${firmware?.file_name}"`}>
              <FontAwesomeIcon
                className="cp-primary-text"
                icon={faDownload}
                onClick={downloadFirmware}
              />
            </h4>
          </Split>
        </ItemProperty>
        <ItemProperty
          label="Bluetooth Image Version"
          value={firmware?.bluetooth_version || "n/a"}
        />
        <ItemProperty
          label="Main Board Image Version"
          value={firmware?.main_version || "n/a"}
        />
        <ItemProperty label="Size" value={userFriendlyFileSize(firmware)} />
        <ItemProperty
          label="Connected Devices"
          value={firmware?.device_count ? firmware?.device_count : "None"}
        />
        <ItemProperty required label="Is Test Firmware?">
          <div className="form-check form-switch form-switch-lg">
            <input
              checked={firmware?.is_test_firmware}
              onChange={toggleTestStatus}
              className="form-check-input"
              type="checkbox"
              id="flexSwitchCheckDefault"
            />
          </div>
          <Modal centered isOpen={changingTestStatus}>
            <ModalHeader>Are you sure?</ModalHeader>
            {/* TEST ==> PRODUCTION */}
            {firmware?.is_test_firmware ? (
              <Form
                initialValues={{ confirm: "" }}
                onSubmit={updateFirmware}
                render={({ handleSubmit, values }) => (
                  <form onSubmit={handleSubmit}>
                    <ModalBody>
                      <p>
                        This will remove the &quot;test&quot; flag from{" "}
                        {firmware?.version}.
                      </p>
                      {firmware?.newest_firmware && (
                        <p>
                          Prosthetists will update to this version the next time
                          they connect to a {firmware?.device_type}.
                        </p>
                      )}
                      <p>
                        To proceed, type &apos;release&apos; in the box below.
                      </p>
                      <Field
                        name="confirm"
                        component="input"
                        placeholder="release"
                        className="w-100 rounded p-2 border"
                      />
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        onClick={toggleTestStatus}
                        className="btn-cp fw-bold uppercase">
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        color="danger"
                        className="fw-bold uppercase"
                        disabled={values?.confirm.toLowerCase() !== "release"}>
                        Confirm
                      </Button>
                    </ModalFooter>
                  </form>
                )}></Form>
            ) : (
              <>
                <ModalBody>
                  <p>
                    This will hide {firmware?.version} and
                    {firmware?.newest_prod_version
                      ? " prompt prosthetists to update to " +
                        firmware?.newest_prod_version
                      : " pause updates until a new production version is available"}
                  </p>
                  {firmware?.device_count > 0 ? (
                    <p>
                      This change will affect {firmware?.device_count} active
                      devices
                    </p>
                  ) : (
                    <p>This will not affect any active devices</p>
                  )}
                  <p>Continue?</p>
                </ModalBody>
                <ModalFooter>
                  <Button
                    onClick={toggleTestStatus}
                    className="btn-cp fw-bold uppercase">
                    Cancel
                  </Button>
                  <Button
                    onClick={updateFirmware}
                    color="danger"
                    className="fw-bold uppercase">
                    Confirm
                  </Button>
                </ModalFooter>
              </>
            )}
          </Modal>
        </ItemProperty>
      </ItemGroup>
      <Row className="mt-4 text-center">
        <Col>
          <Button
            color="danger"
            className="fw-bold uppercase"
            onClick={toggleDeleteModal}>
            Delete
          </Button>
        </Col>
        <ConfirmationModal
          dangerous
          title="Are you sure?"
          isOpen={deleteOpen}
          bodyComponent={
            <>
              <p>You are about to permanently delete this firmware version.</p>
              <p>
                Any devices using this firmware version will require an update
                during their next prosthetist visit.
              </p>
              <p>
                This will affect {firmware.device_count || "zero"} active
                devices
              </p>
              <p>Continue?</p>
            </>
          }
          toggle={toggleDeleteModal}
          confirm={deleteFirmware}
        />
      </Row>
    </Container>
  ) : (
    <h1 className="text-center m-4">Loading...</h1>
  );
};

export default FirmwareView;
