import { Col } from "reactstrap";
import PropTypes from "prop-types";
import React from "react";

const FieldCol = ({ children }) => (
  <Col md="6" className="my-2">
    {children}
  </Col>
);

FieldCol.propTypes = {
  children: PropTypes.any,
};

export default FieldCol;
