import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import React from "react";

const NotFound = () => (
  <div className="text-center m-3">
    <FontAwesomeIcon className="m-3" icon={faSearch} size="6x" />
    <h3>
      Sorry, we couldn&apos;t find that page. Try going <Link to="/">home</Link>
    </h3>
  </div>
);
export default NotFound;
