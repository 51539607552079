import { Button, Col, Container, Row } from "reactstrap";
import { collegeParkFlatLogo } from "../../../assets/images";
import { Field, Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import FieldLabel from "../shared/FieldLabel";
import NoAuthApi from "../../api/NoAuthApi";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Split from "../shared/Split";
import TokenService from "../../utils/TokenService";

const Login = ({ isAuthenticated = false, login }) => {
  let navigate = useNavigate();

  //state
  const [loading, setLoading] = useState();

  useEffect(() => {
    if (isAuthenticated) navigate("/");
  }, []);

  const submit = async (vals) => {
    setLoading(true);
    NoAuthApi.post("/login", { ...vals })
      .then((res) => {
        TokenService.setUser(res.data.user);
        TokenService.updateAccessToken(res.data.access_token);
        TokenService.updateRefreshToken(res.data.refresh_token);
        login();
      })
      .finally(() => setLoading());
  };
  return (
    <>
      <Container>
        <Row>
          <Col lg="5" md="7" className="mx-auto p-4 border bg-white cp-rounded">
            <Form
              onSubmit={submit}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <div className="text-center mb-5">
                    <img
                      src={collegeParkFlatLogo}
                      alt="College Park Logo"
                      className="w-75 rounded"
                    />
                  </div>
                  <h3>Log In</h3>
                  <div className="my-3">
                    <FieldLabel label="Email" />
                    <Field
                      name="email"
                      required
                      className="cp-input"
                      component="input"
                    />
                  </div>
                  <div className="my-3">
                    <FieldLabel label="Password" />
                    <Field
                      name="password"
                      required
                      className="cp-input"
                      component="input"
                      type="password"
                    />
                  </div>
                  <Split>
                    <Button className="btn-cp" type="submit" disabled={loading}>
                      Log{loading ? "ging" : ""} In
                    </Button>
                    <Button
                      className="btn-cp-secondary"
                      onClick={() => navigate("/forgot-password")}>
                      Forgot Password?
                    </Button>
                  </Split>
                </form>
              )}></Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

Login.propTypes = {
  isAuthenticated: PropTypes.bool,
  login: PropTypes.func.isRequired,
};

export default Login;
