import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import App from "../components/App";
import React from "react";
import ReactDOM from "react-dom";
import store from "../redux/store";

document.addEventListener("DOMContentLoaded", () => {
  ReactDOM.render(
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>,
    document.body.appendChild(document.createElement("div"))
  );
});
