import { Navigate, Outlet } from "react-router-dom";
import PropTypes from "prop-types";
import React from "react";

const PrivateRoute = ({ isAuthenticated = false }) => {
  return <>{isAuthenticated ? <Outlet /> : <Navigate to="/login" />}</>;
};

PrivateRoute.propTypes = {
  isAuthenticated: PropTypes.bool,
};

export default PrivateRoute;
