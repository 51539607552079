import { Col, Container, Row } from "reactstrap";
import { FEET, KNEES } from "../../utils/constants";
import { useNavigate, useParams } from "react-router-dom";
import Api from "../../api/Api";
import React, { useEffect, useState } from "react";
import Table from "../table/Table";

const DeviceList = () => {
  const { deviceType = "" } = useParams();
  const validDeviceTypes = [FEET, KNEES];
  const [devices, setDevices] = useState([]);

  const navigate = useNavigate();
  const navigateToItem = (id) => navigate(`/devices/${deviceType}/` + id);
  const getDevices = () => {
    Api.get(`/${deviceType}`).then((res) => setDevices(res.data.devices || []));
  };

  useEffect(() => {
    if (!validDeviceTypes.includes(deviceType.toLowerCase())) {
      navigate("/devices");
      return;
    }
    getDevices();
  }, [deviceType]);

  const columns = [
    { label: "Serial Number", accessor: "serial_number", searchable: true },
    { label: "Connected User", accessor: "user", searchable: true },
    {
      label: "Firmware Version",
      accessor: "firmware_version",
      searchable: true,
    },
  ];

  return (
    <Container>
      <Row>
        <Col className="cp-header-text">
          <h1>{deviceType.charAt(0).toUpperCase() + deviceType.slice(1)}</h1>
        </Col>
      </Row>
      <Row>
        <Table data={devices} columns={columns} rowClick={navigateToItem} />
      </Row>
    </Container>
  );
};

export default DeviceList;
