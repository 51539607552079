import { Button, Container } from "reactstrap";
import React from "react";

const DebugPage = () => (
  <Container className="text-center">
    <a href="cpss://debug" target="_blank" rel="noreferrer">
      <Button className="btn-cp">Enter Debug Mode</Button>
    </a>
  </Container>
);

export default DebugPage;
