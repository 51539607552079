import { Col } from "reactstrap";
import PropTypes from "prop-types";
import React from "react";
import Split from "../shared/Split";

const LinkTile = ({ image, onClick, title }) => (
  <Col md="6" className="my-1" onClick={onClick}>
    <Split className="p-3 border hoverable shadow-on-hover cp-rounded bg-white">
      {image}
      <h4 className="ms-2">{title}</h4>
    </Split>
  </Col>
);

LinkTile.propTypes = {
  image: PropTypes.any,
  onClick: PropTypes.func,
  title: PropTypes.string,
};

export default LinkTile;
