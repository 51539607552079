import PropTypes from "prop-types";
import React from "react";

const FieldLabel = ({ label = "LABEL", required = false }) => (
  <div className="my-2" title={`${label}${required ? " (required)" : ""}`}>
    {label}
    {required && <span className="cp-secondary-dark-text">&nbsp;*</span>}
  </div>
);

FieldLabel.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
};

export default FieldLabel;
