import { Button, Col, Container, Row } from "reactstrap";
import { triggerRefresh } from "../../redux/reducers/siteReducer";
import { useDispatch } from "react-redux";
import Api from "../../api/Api";
import ConfirmationModal from "../shared/ConfirmationModal";
import EditUserForm from "../forms/EditUserForm";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import TokenService from "../../utils/TokenService";
import UpdatePasswordForm from "../forms/UpdatePasswordForm";
import ViewHeader from "../shared/ViewHeader";

const MyAccount = () => {
  const dispatch = useDispatch();
  const [account, setAccount] = useState();

  // handle delete modal
  const [deleteOpen, setDeleteOpen] = useState();
  const toggleDeleteOpen = () => setDeleteOpen(!deleteOpen);

  const refreshAccount = () =>
    Api.get("/my-account").then((res) => {
      setAccount(res.data.user);
      dispatch(triggerRefresh());
      TokenService.setUser(res.data.user);
    });

  useEffect(() => refreshAccount(), []);

  const updateAccount = async (vals, form) => {
    Api.patch("/my-account", {
      ...vals,
      state: vals.country_code.value === "US" ? vals.state?.value : null,
      country_code: vals.country_code.value,
    }).then(() => {
      refreshAccount();
      form.reset();
      toast.success("Details updated successfully");
    });
  };

  const deleteMyAccount = () =>
    Api.delete("/my-account").then(
      () => {
        toggleDeleteOpen();
        TokenService.clearStorage();
        window.location.reload(true);
      },
      (err) => console.error(err)
    );

  return (
    <Container>
      <ViewHeader>My Account</ViewHeader>
      {account && (
        <>
          <EditUserForm user={account} submit={updateAccount} />
          <UpdatePasswordForm />
          <Row>
            <Col className="mt-4 text-center">
              <Button
                color="danger"
                className="fw-bold"
                onClick={toggleDeleteOpen}>
                DELETE ACCOUNT
              </Button>
              <ConfirmationModal
                dangerous
                title="Are you sure?"
                isOpen={deleteOpen}
                bodyComponent={
                  <>
                    <p>
                      This will permanently delete your account and sign you out
                      of this web portal.
                    </p>
                    <p>
                      This action is <u>not reversible.</u> Continue?
                    </p>
                  </>
                }
                toggle={toggleDeleteOpen}
                confirm={deleteMyAccount}
              />
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default MyAccount;
