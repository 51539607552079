import PropTypes from "prop-types";
import React from "react";

const ApprovalStatus = ({ status = false }) => (
  <div
    className="form-check form-switch form-switch-md unselectable"
    title={`Edit this user to change their approval status`}>
    <input
      disabled
      checked={status}
      className="form-check-input"
      type="checkbox"
      id="flexSwitchCheckDefault"
    />
    <label className="fw-bold pt-1 mx-3">
      <p>{status ? "Approved" : "Unapproved"}</p>
    </label>
  </div>
);

ApprovalStatus.propTypes = {
  status: PropTypes.bool,
};

export default ApprovalStatus;
