import { Button, Container, Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import Api from "../../api/Api";
import React, { useEffect, useState } from "react";
import Split from "../shared/Split";
import Table from "../table/Table";
import UserReportsModal from "./UserReportsModal";

const UsersList = () => {
  const [users, setUsers] = useState([]);
  const [reportModalOpen, setReportModalOpen] = useState();
  const toggleReportModalOpen = () => setReportModalOpen(!reportModalOpen);

  useEffect(() => getUsers(), []);

  let navigate = useNavigate();
  const goToUser = (id) => {
    navigate("/users/" + id);
  };

  const getUsers = () => {
    Api.get("/users").then((res) => setUsers(res.data.users || []));
  };

  const tableColumns = [
    { label: "Name", accessor: "full_name", searchable: true },
    { label: "Email", accessor: "email", searchable: true },
    { label: "Account Type", accessor: "role", searchable: true },
    { label: "Clinic", accessor: "clinic", searchable: true },
    { label: "City", accessor: "city", searchable: true },
    { label: "State", accessor: "state", searchable: true },
    { label: "Created", accessor: "created" },
    { label: "Icon Trained?", accessor: "icon_trained" },
    { label: "Odyssey Trained?", accessor: "odyssey_trained" },
  ];

  return (
    <Container>
      <Row>
        <Split className="w-100">
          <h1>Users</h1>
          <div>
            <Button className="btn-cp" onClick={toggleReportModalOpen}>
              Reports
            </Button>
            <UserReportsModal
              isOpen={reportModalOpen}
              toggle={toggleReportModalOpen}
            />
            <Link to="/users/create">
              <Button
                id="addUser"
                className="btn-cp-secondary border text-nowrap">
                <span>create</span>
              </Button>
            </Link>
          </div>
        </Split>
      </Row>
      <Row>
        <Table columns={tableColumns} data={users} rowClick={goToUser} />
      </Row>
    </Container>
  );
};
export default UsersList;
