import { Container } from "reactstrap";
import {
  iconFirmware,
  iconThumbnail,
  odysseyFirmware,
  odysseyThumbnail,
  user,
  users,
} from "../../../assets/images";
import { useNavigate } from "react-router-dom";
import HomeRow from "./HomeRow";
import LinkTile from "./LinkTile";
import PropTypes from "prop-types";
import React from "react";

const Home = ({ isAdmin = false }) => {
  const navigate = useNavigate();

  const navTileImage = (image, alt) => (
    <img width="100" height="auto" src={image} alt={alt} />
  );

  return (
    <Container className="unselectable">
      {isAdmin && (
        <>
          <HomeRow title="Devices">
            <LinkTile
              title="FEET"
              image={navTileImage(odysseyThumbnail, "Odyssey thumbnail")}
              onClick={() => navigate("/devices/feet")}
            />
            <LinkTile
              title="KNEES"
              image={navTileImage(iconThumbnail, "Icon thumbnail")}
              onClick={() => navigate("/devices/knees")}
            />
          </HomeRow>
          <HomeRow title="Firmware">
            <LinkTile
              title="FOOT FIRMWARE"
              image={navTileImage(odysseyFirmware)}
              onClick={() => navigate("/firmware/foot")}
            />
            <LinkTile
              title="KNEE FIRMWARE"
              image={navTileImage(iconFirmware)}
              onClick={() => navigate("/firmware/knee")}
            />
          </HomeRow>
        </>
      )}
      <HomeRow title="Users">
        {isAdmin && (
          <LinkTile
            title="ALL USERS"
            image={navTileImage(users, "Icon indicating multiple users")}
            onClick={() => navigate("/users")}
          />
        )}
        <LinkTile
          title="MY ACCOUNT"
          image={navTileImage(user, "Icon indicating a single user")}
          onClick={() => navigate("/my-account")}
        />
      </HomeRow>
    </Container>
  );
};

Home.propTypes = {
  isAdmin: PropTypes.bool,
};

export default Home;
