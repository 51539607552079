import { createSlice } from "@reduxjs/toolkit";

export const siteSlice = createSlice({
  name: "site",
  initialState: {
    refreshState: false,
  },
  reducers: {
    triggerRefresh: (state) => {
      state.refreshState = !state.refreshState;
    },
  },
});

export const { triggerRefresh } = siteSlice.actions;

export default siteSlice.reducer;
