import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import Api from "../../api/Api";
import fileDownload from "js-file-download";
import PropTypes from "prop-types";
import React from "react";
import Split from "../shared/Split";

const UserReportsModal = ({ isOpen = false, toggle }) => {
  const untrainedUserReport = () =>
    Api({
      method: "GET",
      url: "untrained-user-report",
      responseType: "blob",
    }).then((response) => {
      const date = new Date();
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = (date.getDate()).toString().padStart(2, '0');
      fileDownload(
        response.data,
        `UntrainedUsersReport-${`${year}-${month}-${day}`}.csv`
      );
    });

  return (
    <Modal centered isOpen={isOpen} toggle={toggle}>
      <ModalHeader>User Reports</ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <Split>
              <div>
                <h5>Untrained Users Report</h5>
                <p>
                  CSV of users that have yet to complete training for at least
                  one device type.
                </p>
              </div>
              <div>
                <Button
                  className="btn-cp-secondary"
                  onClick={untrainedUserReport}>
                  Generate
                </Button>
              </div>
            </Split>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button className="btn-cp" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

UserReportsModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
};

export default UserReportsModal;
