import { Col, Row } from "reactstrap";
import PropTypes from "prop-types";
import React from "react";

const ItemGroupTitle = ({ title }) => (
  <Row className="mt-4">
    <Col>
      <h4>{title}</h4>
    </Col>
  </Row>
);

ItemGroupTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default ItemGroupTitle;
