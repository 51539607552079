import { Container } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import TokenService from "../utils/TokenService";

const NavLink = ({ onClick, title }) => (
  <NavDropdown.Item onClick={onClick}>{title}</NavDropdown.Item>
);

NavLink.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
};

const Navigation = ({ isAuthenticated = false, logout }) => {
  let navigate = useNavigate();
  const { refreshState } = useSelector((state) => ({
    refreshState: state.site.refreshState,
  }));
  const [expanded, setExpanded] = useState(false);
  const [user, setUser] = useState();

  const collapse = () => setExpanded(false);
  const toggleExpanded = () => setExpanded(expanded ? false : "expanded");
  const logoutAction = () => {
    collapse();
    logout();
  };

  const goTo = (link) => {
    collapse();
    navigate(link);
  };

  useEffect(() => {
    if (isAuthenticated) setUser(TokenService.getUser());
    else setUser();
  }, [isAuthenticated, refreshState]);

  return (
    <Navbar
      id="navbar"
      sticky="top"
      variant="light"
      expand="sm"
      expanded={expanded}
      className={`unselectable header bg-white ${expanded ? "" : "shadow-sm"}`}>
      <Container className={`bg-white ${expanded ? "cp-shadow" : ""}`}>
        <Navbar.Brand
          as={Link}
          to="/"
          className="text-decoration-none cp-header-text">
          Stride Studio
        </Navbar.Brand>
        {isAuthenticated && (
          <>
            <Navbar.Toggle aria-controls="navbar" onClick={toggleExpanded} />
            <Navbar.Collapse id="navbar" className="justify-content-end">
              <Nav>
                {TokenService.isUserAdmin() && (
                  <>
                    <NavDropdown title="Devices">
                      <NavLink
                        onClick={() => goTo("/devices/feet")}
                        title="Feet"
                      />
                      <NavLink
                        onClick={() => goTo("/devices/knees")}
                        title="Knees"
                      />
                    </NavDropdown>
                    <NavDropdown title="Firmware">
                      <NavLink
                        onClick={() => goTo("/firmware/foot")}
                        title="Foot"
                      />
                      <NavLink
                        onClick={() => goTo("/firmware/knee")}
                        title="Knee"
                      />
                    </NavDropdown>
                    <Nav.Link as={Link} to="/users" onClick={collapse}>
                      Users
                    </Nav.Link>
                    <span className="d-none d-sm-block">&emsp;</span>
                  </>
                )}
                <NavDropdown title={user?.first_name || "User"}>
                  <NavDropdown.Item
                    as={Link}
                    to="/my-account"
                    onClick={collapse}>
                    My Account
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={logoutAction}>
                    Log Out
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </>
        )}
      </Container>
    </Navbar>
  );
};

Navigation.propTypes = {
  isAuthenticated: PropTypes.bool,
  logout: PropTypes.func,
};

export default Navigation;
