import PropTypes from "prop-types";
import React from "react";

const TableBody = ({ rowClick = () => {}, tableData = [], columns = [] }) => (
  <tbody>
    {tableData.map((data) => (
      <tr
        key={data.id}
        onClick={() => rowClick(data.id)}
        className="user-item hoverable">
        {columns.map(({ accessor }) => (
          <td
            key={accessor}
            className="py-4 border-bottom"
            style={{ wordWrap: "break-word" }}>
            {data[accessor] || "--"}
          </td>
        ))}
      </tr>
    ))}
  </tbody>
);

TableBody.propTypes = {
  rowClick: PropTypes.func,
  tableData: PropTypes.array,
  columns: PropTypes.array,
};

export default TableBody;
