import PropTypes from "prop-types";
import React from "react";

const TrainingStatus = ({ status = false }) => {
  return (
    <div
      className="form-check form-switch form-switch-md unselectable"
      title={`Edit this user to change their training status`}>
      <input
        disabled
        checked={status}
        className="form-check-input"
        type="checkbox"
        id="flexSwitchCheckDefault"
      />
      <label className="fw-bold pt-1 mx-3">
        <p>{status ? "Trained" : "Untrained"}</p>
      </label>
    </div>
  );
};

TrainingStatus.propTypes = {
  status: PropTypes.bool,
};

export default TrainingStatus;
