import { Navigate, Outlet } from "react-router-dom";
import PropTypes from "prop-types";
import React from "react";

const AdminRoute = ({ isAdmin = false }) => {
  return <>{isAdmin ? <Outlet /> : <Navigate to="/my-account" />}</>;
};

AdminRoute.propTypes = {
  isAdmin: PropTypes.bool,
};

export default AdminRoute;
