import { Container } from "reactstrap";
import { useNavigate } from "react-router-dom";
import Api from "../../api/Api";
import EditUserForm from "../forms/EditUserForm";
import React from "react";

const CreateUserPage = () => {
  const navigate = useNavigate();
  const navigateToUser = (id) => navigate("/users/" + id);
  const navigateToUsersList = () => navigate("/users");
  const createUser = async (vals) =>
    Api.post("/users", {
      ...vals,
      state: vals.country_code.value === "US" ? vals.state?.value : null,
      country_code: vals.country_code.value,
    }).then((res) => navigateToUser(res.data));
  return (
    <Container>
      <EditUserForm admin submit={createUser} cancel={navigateToUsersList} />
    </Container>
  );
};

export default CreateUserPage;
