import { Outlet } from "react-router-dom";
import Navigation from "../Navigation";
import PropTypes from "prop-types";
import React from "react";

const SiteContainerRoute = ({ isAuthenticated, logout }) => {
  return (
    <div className="cp-text">
      <Navigation isAuthenticated={isAuthenticated} logout={logout} />
      <div className="app cp-light-bg p-3">
        <Outlet />
      </div>
    </div>
  );
};

SiteContainerRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
};

export default SiteContainerRoute;
