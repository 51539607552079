import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

const ConfirmationModal = ({
  isOpen = false,
  dangerous = false,
  title,
  body,
  bodyComponent,
  toggle,
  confirm,
}) => (
  <Modal isOpen={isOpen} centered toggle={toggle}>
    <ModalHeader>
      <h4>{title}</h4>
    </ModalHeader>
    <ModalBody>{body ? body : bodyComponent}</ModalBody>
    <ModalFooter>
      <Button color="secondary" className="fw-bold uppercase" onClick={toggle}>
        CANCEL
      </Button>
      <Button
        className={classNames("fw-bold uppercase", {
          "btn-danger": dangerous,
          "btn-cp": !dangerous,
        })}
        onClick={confirm}>
        CONFIRM
      </Button>
    </ModalFooter>
  </Modal>
);

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool,
  dangerous: PropTypes.bool,
  title: PropTypes.string.isRequired,
  body: PropTypes.string,
  bodyComponent: PropTypes.node,
  toggle: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
};

export default ConfirmationModal;
