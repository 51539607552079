import { BooleanInput } from "../shared/FieldInput";
import { Button, Col, Container, Row } from "reactstrap";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { Field, Form } from "react-final-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FOOT, KNEE } from "../../utils/constants";
import { useNavigate, useParams } from "react-router-dom";
import Api from "../../api/Api";
import FieldLabel from "../shared/FieldLabel";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";

const UploadFirmwareForm = () => {
  const { deviceType } = useParams();
  const navigate = useNavigate();

  const [errors, setErrors] = useState();
  const [submitting, setSubmitting] = useState();

  const validDeviceTypes = [FOOT, KNEE];

  useEffect(() => {
    if (!validDeviceTypes.includes(deviceType)) navigate("/");
  }, [deviceType]);

  const submit = (vals) => {
    setErrors();
    setSubmitting(true);
    let file = document.getElementById("fileUpload");
    if (!file.files[0].name.endsWith(".zip")) {
      setErrors("Please upload a zip archive file");
      return;
    }

    let firmware = new FormData();
    firmware.append("attachment", file.files[0]);
    firmware.append("file_name", file.files[0].name);
    firmware.append("size", file.files[0].size);
    firmware.append("is_test_firmware", vals.is_test_firmware || false);

    Api.post(`firmware/${deviceType}/upload`, firmware)
      .then(() => {
        toast.success(`Firmware successfully created`);
        navigate("/firmware/" + deviceType);
      })
      .finally(() => setSubmitting());
  };

  const footBluetooth = {
    s0: {
      file: "bluetooth_s0_image_update.bin",
      file_size: 1234,
      file_crc: "0x1234abcd",
    },
    version: {
      major: 1,
      minor: 2,
      patch: 3,
    },
  };

  const kneeBluetooth = {
    s0: {
      file: "bluetooth_s0_image_update.bin",
      file_size: 1234,
      file_crc: "0x1234abcd",
    },
    s1: {
      file: "bluetooth_s1_image_update.bin",
      file_size: 1234,
      file_crc: "0x1234abcd",
    },
    version: {
      major: 1,
      minor: 2,
      patch: 3,
    },
  };

  const exampleJson = {
    icd_version: 1,
    version: {
      major: 1,
      minor: 2,
      patch: 3,
    },
    bluetooth: deviceType === FOOT ? footBluetooth : kneeBluetooth,
    main: {
      file: "main_board_image_update.dat",
      version: {
        major: 1,
        minor: 2,
        patch: 3,
      },
    },
  };

  const copyExampleText = () => {
    let copyText = JSON.stringify(exampleJson, undefined, 2);
    navigator.clipboard.writeText(copyText);
    toast.success("Copied text");
  };

  return (
    <Container>
      <Row>
        <Col>
          <h1>Upload {deviceType} firmware</h1>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mx-auto">
          <h3>Before uploading:</h3>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mx-auto">
          <ul>
            <li>Ensure your file is in .zip format</li>
            <li>
              Ensure your zip contains a &quot;manifest.json&quot; file with the
              following format:
              <div className="bg-dark text-light p-4 rounded position-relative">
                <FontAwesomeIcon
                  className="hoverable position-absolute end-0 me-4"
                  icon={faCopy}
                  size="xl"
                  title="Copy Example"
                  onClick={copyExampleText}
                />
                <pre lang="json">
                  {JSON.stringify(exampleJson, undefined, 2)}
                </pre>
              </div>
            </li>
          </ul>
        </Col>
      </Row>
      <Form
        onSubmit={submit}
        render={({ handleSubmit, pristine }) => (
          <form onSubmit={handleSubmit}>
            <Row>
              <Col md="6" className="mx-auto">
                <FieldLabel label="File" required />
                <Field
                  required
                  id="fileUpload"
                  component="input"
                  type="file"
                  accept=".zip"
                  name="file"
                  className="w-100 rounded p-2"
                />
              </Col>
            </Row>
            <Row>
              <Col md="6" className="mx-auto">
                <FieldLabel label="Is test firmware?" />
                <Field
                  name="is_test_firmware"
                  type="checkbox"
                  render={BooleanInput}
                />
              </Col>
            </Row>
            {errors && (
              <Row>
                <Col className="text-danger text-center">
                  <h4>Errors:</h4>
                  {errors.map((e, x) => (
                    <div key={x}>{e}</div>
                  ))}
                </Col>
              </Row>
            )}
            <Row>
              <Col md="6" className="mx-auto text-right">
                <Button
                  className="btn-cp-secondary"
                  disabled={pristine || submitting}
                  type="submit">
                  Upload{submitting && "ing"}
                </Button>
              </Col>
            </Row>
          </form>
        )}></Form>
    </Container>
  );
};

export default UploadFirmwareForm;
