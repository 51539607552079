const USER_NAME = "USER";
const ACCESS_TOKEN_NAME = "ACCESS";
const REFRESH_TOKEN_NAME = "REFRESH";

class TokenService {
  //HELPERS
  getItem(key) {
    return JSON.parse(localStorage.getItem(key));
  }

  setItem(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  removeItem(key) {
    localStorage.removeItem(key);
  }

  clearStorage() {
    this.removeItem(USER_NAME);
    this.removeItem(ACCESS_TOKEN_NAME);
    this.removeItem(REFRESH_TOKEN_NAME);
  }

  getUser() {
    return this.getItem(USER_NAME);
  }

  setUser(user) {
    this.setItem(USER_NAME, user);
  }

  getAccessToken() {
    return this.getItem(ACCESS_TOKEN_NAME);
  }

  updateAccessToken(token) {
    return this.setItem(ACCESS_TOKEN_NAME, token);
  }

  getRefreshToken() {
    return this.getItem(REFRESH_TOKEN_NAME);
  }

  updateRefreshToken(token) {
    return this.setItem(REFRESH_TOKEN_NAME, token);
  }

  isUserAdmin() {
    return this.getItem(USER_NAME)?.is_admin || false;
  }
}

export default new TokenService();
