import { Col, Row } from "reactstrap";
import PropTypes from "prop-types";
import React from "react";

const HomeRow = ({ title, children }) => (
  <Row className="justify-content-center mb-4">
    <Col md="9" className="text-center">
      <Row className="mt-4 mb-2">
        <h3>{title}</h3>
      </Row>
      <Row>{children}</Row>
    </Col>
  </Row>
);

HomeRow.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
};

export default HomeRow;
