import { Col } from "reactstrap";
import PropTypes from "prop-types";
import React from "react";

const ItemProperty = ({ label, value, children }) => (
  <Col xxl="6" xl="6" lg="6" md="6" className="p-0 py-2">
    <h5 className="border-bottom text-secondary">{label}</h5>
    <p>{value}</p>
    {children}
  </Col>
);

ItemProperty.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
  ]),
  children: PropTypes.node,
  required: PropTypes.bool,
};

export default ItemProperty;
