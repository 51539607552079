import axios from "axios";
import toast from "react-hot-toast";
import TokenService from "../utils/TokenService";

/**
 * Axios instance which sets authorization headers
 */
const Api = axios.create({
  baseURL: "/api/v1",
});


/**
 * Send language header and auth token in request
 */
Api.interceptors.request.use((config) => {
  const token = TokenService.getAccessToken();
  config.headers.Authorization = `Bearer ${token}`;
  config.headers.Language = window.navigator.language;
  return config;
});

const clearAndReload = () => {
  TokenService.clearStorage();
  location.reload();
};

const UNAUTHORIZED = 401;

/**
 * Handle errors
 */
Api.interceptors.response.use(
  (response) => response,
  async (error) => {
    // https://www.bezkoder.com/react-refresh-token/
    if (!error.response) return;
    const originalConfig = error.config;

    // don't retry request if it's not a 401
    if (error.response.status !== UNAUTHORIZED) {
      toast.error(error.response?.data?.error || "An error occurred with your request");
      return Promise.reject(error);
    }

    // check if request has been re-tried, skip re-running refresh-token request
    if (!originalConfig._retry && !originalConfig.url.includes("refresh-token")) {
      originalConfig._retry = true;
      try {
        const refreshToken = TokenService.getRefreshToken();
        const rs = await Api.post("/refresh-token", {
          refresh_token: refreshToken,
        });

        const { access_token, refresh_token } = rs.data;
        TokenService.updateAccessToken(access_token);
        TokenService.updateRefreshToken(refresh_token);

        // retry original request now that token has been updated
        return Api(originalConfig);
      } catch (_error) {
        if (_error.response.status === UNAUTHORIZED) {
          clearAndReload();
        }

        return Promise.reject(_error);
      }
    }
    clearAndReload();
  },
);

export default Api;
