import "../../styles/input.css";
import PropTypes from "prop-types";
import React from "react";
import Select from "react-select";

const required = (val) => (val ? undefined : "Required");

const reactSelectRequired = (val) => (val?.value ? undefined : "Required");

const email = (val) =>
  //eslint-disable-next-line
  val && /[\w+\-.]+@[a-z\d\-]+(\.[a-z\d\-]+)*\.[a-z]+/i.test(val)
    ? undefined
    : "Invalid email format";

const TextInput = ({ input, placeholder, meta = {} }) => (
  <div>
    <input
      {...input}
      placeholder={placeholder ? placeholder : ""}
      className="w-100 p-2 rounded border"
    />
    <div className="text-danger me-2">
      {meta.error && meta.touched && meta.error}
    </div>
  </div>
);

TextInput.propTypes = {
  input: PropTypes.any,
  meta: PropTypes.object,
  placeholder: PropTypes.string,
  debug: PropTypes.bool,
};

const BooleanInput = ({ input, meta = {} }) => (
  <div>
    <div className="form-check form-switch form-switch-md">
      <input
        {...input}
        className="form-check-input"
        type="checkbox"
        id="flexSwitchCheckDefault"
      />
    </div>
    {meta.error && meta.touched && (
      <div className="text-danger">{meta.error}</div>
    )}
  </div>
);

BooleanInput.propTypes = {
  input: PropTypes.element,
  meta: PropTypes.object,
};

const ReactSelectField = ({ input, meta = {}, ...rest }) => (
  <>
    <Select
      {...input}
      {...rest}
      searchable
      isClearable
      className="hide-input-style"
    />
    {meta.error && meta.touched && (
      <div className="text-danger">{meta.error}</div>
    )}
  </>
);

ReactSelectField.propTypes = {
  input: PropTypes.any,
  rest: PropTypes.any,
  meta: PropTypes.object,
};

export {
  TextInput,
  BooleanInput,
  ReactSelectField,
  required,
  reactSelectRequired,
  email,
};
