import { FOOT, KNEE } from "../../utils/constants";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "../shared/RouterHelpers";
import { useState } from "react";
import NoAuthApi from "../../api/NoAuthApi";
import Player from "@vimeo/player";
import React from "react";
import toast from "react-hot-toast";

const TrainingPage = () => {
  const query = useQuery();
  const navigate = useNavigate();

  const instructionTextStrings = {
    de: "Sehen Sie das Video bis zum Ende an, um Ihre Schulung abzuschließen",
    en: "Please watch the video to the end to complete your training",
    es: "Mire el video hasta el final para completar su entrenamiento",
    fr: "Veuillez regarder la vidéo jusqu'à la fin pour terminer votre formation",
  };

  const errorTextStrings = {
    de: "Fehler beim Abschließen der Schulung: Seriennummer oder Validierungs-Token fehlt",
    en: "Error completing training: missing serial number or validation token",
    es: "Error al completar el entrenamiento: falta el número de serie o el token de validación",
    fr: "Erreur pour terminer la formation : numéro de série ou jeton de validation manquant",
  };

  const footVideoId = "938352926";
  const kneeVideoId = "936682354";

  const { type } = useParams();
  const [error, setError] = useState();

  const [displayText, setDisplayText] = useState("");

  useEffect(() => {
    let videoLink = "";
    // set video based on device type
    switch (type.toLocaleLowerCase()) {
      case FOOT:
        videoLink = footVideoId;
        break;
      case KNEE:
        videoLink = kneeVideoId;
        break;
      default:
        toast.error("No training available for device type: " + type);
        navigate("/");
    }
    const options = {
      id: videoLink,
      loop: false,
      controls: true,
    };
    const player = new Player("trainingVideo", options);
    player.on("ended", updateTrainingStatus);

    // Handle test environment for Brandon's UI testing
    const metas = document.getElementsByTagName("meta");
    if (
      Array.from(metas).some((meta) => meta.getAttribute("name") === "ui-test")
    ) {
      setTimeout(() => updateTrainingStatus(), 5000);
    }

    // set display text based on browser region
    const browserLanguage = query.get("lang");
    switch (browserLanguage) {
      case "de":
        setDisplayText(instructionTextStrings.de);
        break;
      case "es":
        setDisplayText(instructionTextStrings.es);
        break;
      case "fr":
        setDisplayText(instructionTextStrings.fr);
        break;
      case "en":
      default:
        setDisplayText(instructionTextStrings.en);
    }
  }, []);

  const updateTrainingStatus = async () => {
    const errorText =
      "Error updating training status, please contact College Park support";
    const serialNumber = query.get("serial_number");
    const token = query.get("token");
    if (serialNumber && token) {
      try {
        await NoAuthApi.post(
          "has-completed-training",
          { serial_number: serialNumber },
          { headers: { Authorization: "Bearer " + token } }
        );
        try {
          // this function is used by the mobile app to close the training window,
          // but isn't defined here, hence the disable for eslint
          // eslint-disable-next-line
          InvokeTrainingComplete();
        } catch {
          // pass
        }
        return;
      } catch (e) {
        setError(errorText);
      }
    }
    const browserLanguage = query.get("lang");
    switch (browserLanguage) {
      case "de":
        setError(errorTextStrings.de);
        break;
      case "es":
        setError(errorTextStrings.es);
        break;
      case "fr":
        setError(errorTextStrings.fr);
        break;
      case "en":
      default:
        setError(errorTextStrings.en);
    }
  };

  return (
    <div className="text-center bg-white fill-height">
      {error ? (
        <div className="text-danger">{error}</div>
      ) : (
        <>
          <div id="trainingVideo" className="video"></div>
          <h3 className="m-2 video-text fw-bold">{displayText}</h3>
        </>
      )}
    </div>
  );
};

export default TrainingPage;
