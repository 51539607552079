import { Button, Col, Container, Row } from "reactstrap";
import { collegeParkFlatLogo } from "../../../assets/images";
import { Field, Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import { useQuery } from "../shared/RouterHelpers";
import FieldLabel from "../shared/FieldLabel";
import NoAuthApi from "../../api/NoAuthApi";
import React from "react";

const ResetPassword = () => {
  let query = useQuery();
  let navigate = useNavigate();

  const submit = async (vals) => {
    const headers = {
      Authorization: "Bearer " + query.get("token"),
    };
    NoAuthApi.post(
      "/reset-password",
      { ...vals },
      {
        headers: headers,
      }
    ).then(() => navigate("/"));
  };

  return (
    <Container>
      <Row>
        <Col lg="5" md="7" className="mx-auto border cp-rounded p-4 bg-white">
          <Form
            onSubmit={submit}
            validate={(values) => {
              const errors = {};
              if (!values.password) errors.password = "Must supply a password";
              if (
                values.password &&
                (!values.password.length > 7 ||
                  !values.password.match(/[A-Z]/) ||
                  !values.password.match(/[a-z]/) ||
                  !values.password.match(/\d/))
              )
                errors.password = "Must match password format";
              if (!values.password_confirmation)
                errors.password_confirmation = "Must confirm password";
              if (
                values.password &&
                values.password_confirmation &&
                values.password != values.password_confirmation
              )
                errors.password_confirmation = "New passwords must match";
              return errors;
            }}
            render={({ handleSubmit, values, pristine }) => (
              <form onSubmit={handleSubmit}>
                <div className="text-center mb-5">
                  <img
                    src={collegeParkFlatLogo}
                    alt="College Park Logo"
                    className="w-75 rounded"
                  />
                </div>
                <div>
                  <h3>Reset Password</h3>
                </div>
                <div>
                  <FieldLabel label="New Password" />
                  <Field name="password" required>
                    {({ input, meta }) => (
                      <div>
                        <input
                          {...input}
                          type="password"
                          className="cp-input"
                        />
                        {meta.error && meta.touched && (
                          <span className="text-danger">{meta.error}</span>
                        )}
                      </div>
                    )}
                  </Field>
                </div>
                <div>
                  <FieldLabel label="Confirm Password" />
                  <Field name="password_confirmation" required>
                    {({ input, meta }) => (
                      <div>
                        <input
                          {...input}
                          className="cp-input"
                          type="password"
                        />
                        {meta.error && meta.touched && (
                          <span className="text-danger">{meta.error}</span>
                        )}
                      </div>
                    )}
                  </Field>
                </div>
                <div className="text-secondary my-2">
                  {values.password &&
                  values.password.length > 7 &&
                  values.password.match(/[A-Z]/) &&
                  values.password.match(/[a-z]/) &&
                  values.password.match(/\d/) ? (
                    <>Requirements Met</>
                  ) : (
                    <>
                      <h6>Requirements:</h6>
                      <ul>
                        {(!values.password || values.password.length < 8) && (
                          <li>Must contain at least 8 characters</li>
                        )}
                        {(!values.password ||
                          !values.password.match(/[A-Z]/)) && (
                          <li>Must contain an uppercase letter</li>
                        )}
                        {(!values.password ||
                          !values.password.match(/[a-z]/)) && (
                          <li>Must contain a lowercase letter</li>
                        )}
                        {(!values.password || !values.password.match(/\d/)) && (
                          <li>Must contain a number</li>
                        )}
                      </ul>
                    </>
                  )}
                </div>
                <div className="text-right my-2">
                  <Button className="btn-cp" type="submit" disabled={pristine}>
                    Submit
                  </Button>
                </div>
              </form>
            )}></Form>
        </Col>
      </Row>
    </Container>
  );
};

export default ResetPassword;
